<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Admission No</th>
          <th>Patient ID</th>
          <th>Patient Name</th>
          <th>Patient Mobile No</th>
          <th v-for="groupName in groupNames">{{ groupName.name }}</th>
          <th class="text-right">Total Bill Amount</th>
          <th class="text-right">Advance Adj</th>
          <th class="text-right">Total Discount</th>
          <th class="text-right">Refund Amount</th>
          <th class="text-right">Received Amount</th>
          <th class="text-right">Net Collection</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in lists" :key="i">
          <td>{{ item.admission_no }}</td>
          <td>{{ item.patient?.patient_id }}</td>
          <td>{{ item.patient?.full_name }}</td>
          <td>{{ item.patient?.mobile_no }}</td>
          <td v-for="(groupName, groupId) in groupNames">
            {{ getGroupAmount(groupId, item.grouped_general) }}
          </td>
          <td class="text-right">{{ commaFormat(item.subtotal_amount) }}</td>
          <td class="text-right">{{ commaFormat(item.sum_advance_adj) }}</td>
          <td class="text-right">{{ commaFormat(item.sum_discount_amount) }}</td>
          <td class="text-right">{{ commaFormat(item.sum_refund_amount) }}</td>
          <td class="text-right">{{ commaFormat(item.sum_total_paid_amount) }}</td>
          <td class="text-right">{{ commaFormat((item.sum_total_paid_amount) - item.sum_refund_amount ) }}</td>

        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="text-center" colspan="4">Total</th>
          <th v-for="groupName in groupNames">{{ commaFormat(groupName.amount) }}</th>
          <th class="text-right">{{ commaFormat(getTotalAmount(lists)) }}</th>
          <th class="text-right">{{ commaFormat(getTotalAmount(lists, 'sum_advance_adj')) }}</th>
          <th class="text-right">{{ commaFormat(getTotalAmount(lists, 'sum_discount_amount')) }}</th>
          <th class="text-right">{{ commaFormat(getTotalAmount(lists, 'sum_refund_amount')) }}</th>
          <th class="text-right">{{ commaFormat(getTotalAmount(lists, 'sum_total_paid_amount')) }}</th>
          <th class="text-right">{{ commaFormat(getTotalAmount(lists, 'sum_total_paid_amount') - getTotalAmount(lists, 'sum_refund_amount')) }}</th>

        </tr>
        </tfoot>
      </table>
      <p v-if="!lists.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";

const props = defineProps({
  lists: Array,
  groupNames: Object
})

const {commaFormat} = figureFormatter()

const getGroupAmount = (groupId, grouped_general) => {
  const findGroupData = grouped_general.find((item) => item.product_group_id == groupId)
  if (findGroupData) {
    return commaFormat(findGroupData.total_amount)
  }
  return 0
}

const getTotalAmount = (items, column='subtotal_amount') => {
  return items.reduce((total, i) => total + i[column], 0);
}
</script>
